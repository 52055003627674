var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-contanier" },
    _vm._l(_vm.typeItems, function (item, $index) {
      return _c(
        "div",
        {
          key: $index,
          class: ["panel", _vm.active === $index ? "active" : ""],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClick(item, $index)
            },
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: item.new,
                expression: "item.new",
              },
            ],
            attrs: { src: require("@/assets/images/bill/new.png"), alt: "" },
          }),
          _c("span", [
            _vm._v(_vm._s(item.name) + "( " + _vm._s(item.length) + " 条记录)"),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }