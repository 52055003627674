<template>
  <Drawer :drawer="drawer" title="跟进记录" :withHeader="false" :size="740" @close="close">
    <div class="view">
      <div class="title">
        <div class="title_left">
          <div class="title-main">
            <img src="@/assets/images/bill/record-1.png">跟进记录
          </div>
          <div class="copy-no">
            <span>提单号：{{ rowData.blNo }}</span>
            <img src="@/assets/images/bill/copy.png" title="复制" @click="copy(rowData.blNo)">
          </div>
        </div>
        <div class="title_right">
          <i class="el-icon-close icon" @click="close" />
        </div>
      </div>
      <div class="container">
        <div class="side">
          <SideTab
            :typeItems="typeItems"
            :defaultActive="active"
            @handleClick="handleClick"
          ></SideTab>
        </div>
        <div class="main">
          <div class="main-contanier" :style="{height: terminologyType.isEnable ? 'calc(100% - 284px)' : 'calc(100% - 174px)'}">
            <div v-for="(item, $index) in mainItem[active]" :key="$index" class="events">
              <div class="events-top">
                <img v-show="item.createdName === 'FEEPOWER'" class="avatar" src="@/assets/images/bill/feepower.png" alt="">
                <img v-show="item.createdName !== 'FEEPOWER'" class="avatar" src="@/assets/images/bill/avatar.png" alt="">
                <div class="events-title">
                  <div class="title-userinfo">
                    <span class="user-name" :style="{ color: item.createdName === 'FEEPOWER' ? '#2395F3' : ''}">{{ item.createdName }}</span>
                    <span class="user-time" :style="{ color: item.createdName === 'FEEPOWER' ? '#2395F3' : ''}">{{ item.createdOn }}</span>
                  </div>
                  <div class="title-status">
                    <!-- {{ item.mblSubStatus|formatterStatus }} -->
                    <img v-show="item.hasNewFollowUp" src="@/assets/images/bill/new.png" alt="">
                  </div>
                </div>
              </div>
              <div
                class="events-bottom"
                :style="{color: item.createdName === 'FEEPOWER' ?
                  filterColor(item.description) || '#2395F3' :
                  ''
                }">
                <span class="goods-label">{{ item.title ? item.title + ':' : '' }}</span>
                <span class="goods-info" v-html="item.description"></span>
              </div>
            </div>
          </div>
          <div class="add">
            <div class="type">
              <el-select
                v-model="terminologyId"
                style="width: 100%;"
                placeholder="请选择跟进记录类型"
                @change="handleChange"
              >
                <el-option v-for="(item, $index) in remarkTypeOptions" :key="$index" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-input
                v-if="terminologyType.isEnable"
                v-model="description"
                type="textarea"
                :maxlength="500"
                :rows="5"
                resize="none"
                placeholder="请输入其他跟进内容，最多输入500字">
              </el-input>
              <div class="submit">
                <div class="btn" @click="send">发送</div>
                <span v-if="terminologyType.isEnable">按下enter换行</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Drawer from './Drawer'
import SideTab from './SideTab.vue'
import { getMBLFollowUpList, addMBLFollowUp } from '@/api/bill'
import config from '@/utils/config'
import { flatten } from '@/utils/common'
import { mapGetters } from 'vuex'
export default {
  components: { Drawer, SideTab },
  filters: {
    formatterStatus(status) {
      const list = flatten(config.billShipSubtabOptions)
      return list.find(a => a.value === status).label
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    billType: {
      type: Number,
      default: 0 // 0 清关 1 提拆交
    }
    // defaultTerminologyId: {
    //   type: Number,
    //   default: 0 // 0 清关 1 提拆交 2 查验跟进
    // }
  },
  data() {
    return {
      active: 0,
      typeItems: [],
      mainItem: [],
      terminologyId: '',
      description: '',
      terminologyType: ''
    }
  },
  computed: {
    ...mapGetters(['billRemarkTypeList']),
    remarkTypeOptions() {
      return this.billRemarkTypeList.filter(a => a.type === this.billType && a.isActived).map(b => {
        return {
          label: b.body,
          value: b.id
        }
      })
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.typeItems = []
        this.mainItem = []
        // this.terminologyId = this.defaultTerminologyId
        // this.active = this.rowData.status
        this.getList()
      }
    }
  },
  mounted() {
    if (this.billRemarkTypeList.length === 0) {
      this.$store.dispatch('getBillRemarkList')
    }
  },
  methods: {
    async getList() {
      const obj = {
        0: [0],
        1: [0, 1],
        2: [0, 1, 2],
        3: [0, 1, 2, 3],
        4: [0, 1, 2, 3, 4]
      }
      const data = {
        id: this.rowData.id,
        mblStatus: obj[this.rowData.status]
      }
      try {
        const result = await getMBLFollowUpList(data)
        if (result.success) {
          this.mainItem = []
          this.active = result.data.length - 1
          // config.billStatusOptions.shift()
          const arr = [...config.billStatusOptions]
          arr.shift()
          console.log('config.billStatusOptions.shift()', arr)
          const sideArr = result.data.map(item => {
            this.mainItem.push(item.events.reverse())
            // console.log('config.billStatusOptions.shift()', arr, config.billStatusOptions)
            item.status = arr.find(a => +a.value === item.status).label
            return {
              name: item.status,
              new: item.hasNewFollowUp,
              length: item.events.length
            }
          })
          this.typeItems = sideArr
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    close() {
      this.mainItem = []
      this.typeItems = []
      this.$emit('close')
    },
    handleClick(v) {
      const { index } = v
      this.active = index
    },
    copy(v) {
      const url = v
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    handleChange(id) {
      this.terminologyType = this.billRemarkTypeList.find(a => a.id === id)
    },
    async send() {
      if (!this.terminologyId) {
        this.$message.warning('请选择更进记录类型')
        return
      }
      const parmas = {
          'mblId': this.rowData.id,
          'description': this.description,
          'terminologyId': this.terminologyId
      }
      try {
        const res = await addMBLFollowUp(parmas)
        if (res.success) {
          this.$message.success('添加成功')
          this.getList()
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    filterColor(v) {
      const obj = {
        '查验7H': '#FF7444',
        '清关放行': '#9D67FE'
      }
      return obj[v] || ''
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #F2F7FF;
    padding: 0 20px;
    box-sizing: border-box;
    .title_left {
      display: flex;
      align-items: center;
      .title-main {
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      .copy-no {
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        height: 32px;
        background: #F7F7F7;
        border-radius: 4px 4px 4px 4px;
        padding: 6px 10px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        img {
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
    .title_right {
      .icon {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .container {
    display: flex;
    width: 100%;
    height: calc(100% - 64px);
  }
  .side {
    width: 212px;
    height: 100%;
    border-right: 1px solid #EEEEEE;
    box-sizing: border-box;
  }
  .main {
    position: relative;
    flex: 1;
    height: 100%;
    background: #FBFBFB;
    border-radius: 0px 0px 0px 0px;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .main-contanier {
    width: calc(100% - 40px);
    padding: 24px 24px 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .events {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    .events-top {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
    }
    .events-bottom {
      position: relative;
      width: calc(100% - 50px);
      height: auto;
      padding: 23px 24px;
      background: #FFFFFF;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #EEEEEE;
      margin-top: 11px;
      margin-left: 52px;
      box-sizing: border-box;
      color: #666666;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -16px;
        left: 16px;
        display: block;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-bottom-color: #FFFFFF;
      }
      &::before {
        border-bottom-color: #EEEEEE;
        top: -17px;
      }
    }
    .goods-label {
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
    }
    .goods-info {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
    }
    .events-title {
      display: flex;
      justify-content: space-between;
      flex: 1;
      height: 100%;
    }
    .title-userinfo {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .title-status {
      display: flex;
      align-items: center;
      align-self: flex-end;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #35BA83;
      img {
        margin-left: 4px;
      }
    }
    .user-name {
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
    .user-time {
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    .avatar {
      width: 44px;
      height: 44px;
      margin-right: 8px;
    }
  }
  .add {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 150px;
    height: auto;
    padding: 30px;
    background: #F7F7F7;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #EEEEEE;
    box-sizing: border-box;
    .submit {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row-reverse;
      margin-top: 10px;
      span {
        height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
        margin-right: 20px;
      }
      .btn {
        width: 80px;
        height: 40px;
        background: #2395F3;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  &:deep(.el-row + .el-row) {
    margin-top: 8px;
  }
  .tag {
    width: 100%;
    height: 48px;
    margin-bottom: 28px;
    &:deep(.el-tabs__header) {
      margin: 0;
    }
    &:deep(.el-tabs__nav-wrap) {
      padding-left: 40px;
      box-sizing: border-box;
      &::after {
        height: 1px;
        background-color: #F2F7FF;
      }
    }
    &:deep(.el-tabs__item) {
      height: 48px;
      line-height: 48px;
      color: #7C8DB5;
    }
    &:deep(.el-tabs__item.is-active) {
      color: #2395F3;
    }
  }
  &:deep(.el-textarea__inner) {
    border: 0;
    background: #F7F7F7;
  }
}
:deep(.el-drawer__body) {
  height: 100%;
}
</style>
