<template>
  <div class="tab-contanier">
    <div v-for="(item, $index) in typeItems" :key="$index" :class="['panel', active === $index ? 'active' : '']" @click.stop="handleClick(item, $index)">
      <img v-show="item.new" src="@/assets/images/bill/new.png" alt="">
      <!-- <svg-icon className="icon" :iconClass="item.icon" /> -->
      <span>{{ item.name }}( {{ item.length }} 条记录)</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    typeItems: {
      type: Array,
      default: function() {
        return []
      }
    },
    defaultActive: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
        active: this.defaultActive
    }
  },
  watch: {
    defaultActive(val) {
      this.active = val
    }
  },
  methods: {
    handleClick(item, index) {
      this.active = index
      this.$emit('handleClick', {
        item,
        index
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tab-contanier {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    border-radius: 4px;
    color: #666666;
    margin-bottom: 14px;
    cursor: pointer;
    .icon {
      margin-left: 40px;
      font-size: 24px;
    }
    span {
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
      text-align: center;
    }
  }
  &:first-child {
    margin-top: 16px;
  }
  & + .panel {
    margin-top: 18px;
  }
  .active {
    position: relative;
    background: #2395F3;
    color: #FFFFFF;
    & ::after {
      content: '';
      position:absolute;
      top: 12px;
      right: -8px;
      border-left:8px solid #2395F3;
      border-top:8px solid transparent;
      border-bottom:8px solid transparent;
    }
  }
}
</style>
