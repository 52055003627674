var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: {
        drawer: _vm.drawer,
        title: "跟进记录",
        withHeader: false,
        size: 740,
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "view" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_left" }, [
            _c("div", { staticClass: "title-main" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/bill/record-1.png") },
              }),
              _vm._v("跟进记录 "),
            ]),
            _c("div", { staticClass: "copy-no" }, [
              _c("span", [_vm._v("提单号：" + _vm._s(_vm.rowData.blNo))]),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/bill/copy.png"),
                  title: "复制",
                },
                on: {
                  click: function ($event) {
                    return _vm.copy(_vm.rowData.blNo)
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title_right" }, [
            _c("i", {
              staticClass: "el-icon-close icon",
              on: { click: _vm.close },
            }),
          ]),
        ]),
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "side" },
            [
              _c("SideTab", {
                attrs: { typeItems: _vm.typeItems, defaultActive: _vm.active },
                on: { handleClick: _vm.handleClick },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "main" }, [
            _c(
              "div",
              {
                staticClass: "main-contanier",
                style: {
                  height: _vm.terminologyType.isEnable
                    ? "calc(100% - 284px)"
                    : "calc(100% - 174px)",
                },
              },
              _vm._l(_vm.mainItem[_vm.active], function (item, $index) {
                return _c("div", { key: $index, staticClass: "events" }, [
                  _c("div", { staticClass: "events-top" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.createdName === "FEEPOWER",
                          expression: "item.createdName === 'FEEPOWER'",
                        },
                      ],
                      staticClass: "avatar",
                      attrs: {
                        src: require("@/assets/images/bill/feepower.png"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.createdName !== "FEEPOWER",
                          expression: "item.createdName !== 'FEEPOWER'",
                        },
                      ],
                      staticClass: "avatar",
                      attrs: {
                        src: require("@/assets/images/bill/avatar.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "events-title" }, [
                      _c("div", { staticClass: "title-userinfo" }, [
                        _c(
                          "span",
                          {
                            staticClass: "user-name",
                            style: {
                              color:
                                item.createdName === "FEEPOWER"
                                  ? "#2395F3"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(item.createdName))]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "user-time",
                            style: {
                              color:
                                item.createdName === "FEEPOWER"
                                  ? "#2395F3"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(item.createdOn))]
                        ),
                      ]),
                      _c("div", { staticClass: "title-status" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.hasNewFollowUp,
                              expression: "item.hasNewFollowUp",
                            },
                          ],
                          attrs: {
                            src: require("@/assets/images/bill/new.png"),
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "events-bottom",
                      style: {
                        color:
                          item.createdName === "FEEPOWER"
                            ? _vm.filterColor(item.description) || "#2395F3"
                            : "",
                      },
                    },
                    [
                      _c("span", { staticClass: "goods-label" }, [
                        _vm._v(_vm._s(item.title ? item.title + ":" : "")),
                      ]),
                      _c("span", {
                        staticClass: "goods-info",
                        domProps: { innerHTML: _vm._s(item.description) },
                      }),
                    ]
                  ),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "add" }, [
              _c(
                "div",
                { staticClass: "type" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择跟进记录类型" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.terminologyId,
                        callback: function ($$v) {
                          _vm.terminologyId = $$v
                        },
                        expression: "terminologyId",
                      },
                    },
                    _vm._l(_vm.remarkTypeOptions, function (item, $index) {
                      return _c("el-option", {
                        key: $index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm.terminologyType.isEnable
                    ? _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: 500,
                          rows: 5,
                          resize: "none",
                          placeholder: "请输入其他跟进内容，最多输入500字",
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "submit" }, [
                    _c("div", { staticClass: "btn", on: { click: _vm.send } }, [
                      _vm._v("发送"),
                    ]),
                    _vm.terminologyType.isEnable
                      ? _c("span", [_vm._v("按下enter换行")])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }